import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('../views/Home')
	},
	{
		path: '/episode',
		name: 'Episode',
		component: () => import('../views/Episode')
	},
	{
		path: '/episode/:contentNo',
		name: 'Episode',
		component: () => import('../views/Episode')
	},
	{
		path: '/data',
		name: 'Data',
		component: () => import('../views/Data')
	},
	{
		path: '/bible',
		name: 'Bible',
		component: () => import('../views/Bible.vue')
	},
	{
		path: '/about',
		name: 'About',
		component: () => import('../views/about/index')
	},
	{
		path: '/map',
		name: 'Map',
		component: () => import('../views/about/Map')
	},
	{
		path: '/language',
		name: 'Language',
		component: () => import('../views/Language')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
