import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'

Vue.config.productionTip = false
Vue.use(VueMeta, {
  attribute: 'data-vue-meta'
});
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#wrapper')
