<template>
    <div id="wrapper" ref="wrapper" :class="pageType + ' _'+lang" :data-page="page">
        <div class="header_bg" ref="headerbg" tabindex="“1”"></div>
        <header-bar ref="header" :lang="lang" />
        <main id="page_main">
			<router-view/>
		</main>
		<footer id="page_footer">
			<footer-view :lang="lang" />
		</footer>
    </div>
</template>

<script>
// import '@/styles/fonts.css'
// import '@/styles/layout.css'
// import '@/styles/pages.css'

import HeaderBar from "@/layout/header"
import FooterView from "@/layout/footer"
import langCommon from '@/language/common'

export default {
    name: 'All',
	components: {
        HeaderBar,
        FooterView
	},
	data: () => ({
    }),
	computed:{
		lang(){
			return this.$store.state.lang;
		},
		pageType(){
			return this.$store.state.pageType;
		},
		page(){
			return this.$store.state.page;
		}
	},
	methods: {
		getLang() {
			const lng = localStorage.getItem("lang");
			if (lng) this.$store.commit("setLanguage",lng);
			else {
				this.$store.commit("setLanguage","kor");
				localStorage.setItem("lang", "kor");
			}
		},
		setPageType(arg){
			this.$store.commit("setPageType",arg.toLowerCase());
		},
		readyPageType(){
			var vm = this;
			var _name = vm.$router.history.current.name;
			if (_name) vm.setPageType(_name);
			else setTimeout(vm.readyPageType, 10);
		},
		setGNB(con) {
			var vm=this,
				_hdr=con.querySelector("header#page_header"), 
				_home = con.querySelector("h1 a"), 
				_btnNav;
			if (vm.$store.state.isMobile) {
				// _hdr.insertAdjacentHTML('beforeend','<button type="button" class="nav_button"></button>');
				_btnNav = _hdr.querySelector(".nav_button");
				_btnNav.addEventListener("click", ()=>{
					con.classList.add("_mode_nav");
				});
				// _home.addEventListener("click", ()=>{
				//     con.classList.add("_mode_nav");
				// });
				_hdr.querySelector("a").addEventListener("touchstart", (e)=>{
					location.href = e.target.getAttribute("href");
				});
				con.querySelector(".header_bg").addEventListener("touchstart", ()=>{
					con.classList.remove("_mode_nav"); 
					_home.classList.remove("_enabled");
				});
			} else {
				_hdr.addEventListener("mouseover", ()=>{
					con.classList.add("_mode_nav");
				});
				con.addEventListener("mouseout", ()=>{
					
					con.classList.remove("_mode_nav"); 
					_home.classList.remove("_enabled")
				});
			}
		},
		loadFonts(){
			let hdr = document.head, fontH = langCommon.embedHdr(), fontB = langCommon.embedBody(), useFont=langCommon.buildRoot();

			hdr.insertAdjacentHTML('beforeend', fontH);
			hdr.insertAdjacentHTML('beforeend', fontB);
			hdr.insertAdjacentHTML('beforeend', useFont);
			// console.log(fontH, fontB);
		}
	},
	created() {
		let vm = this;
		vm.$store.commit("setIsMobile");
		vm.getLang();
		vm.$router.beforeEach(function (to, from, next) { //args :(to, from, next)
			vm.setPageType(to.name);
			from;
			vm.$store.state.closePreview();
			next();
		});
		
	},
	mounted() {
		var vm = this;
		vm.readyPageType();
		vm.setGNB(this.$el);
		vm.loadFonts();
	}
}
</script>



<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
