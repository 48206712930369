import Vue from 'vue'
import Vuex from 'vuex'
import langCommon from '@/language/common'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		BASE_URL:process.env.BASE_URL,
		API_PATH : process.env.VUE_APP_API_PATH,
		isMobile : false,
		lang:"kor",
		changeLang:"",
		pageType:"",
		page:"home",
		nav:langCommon.nav,
		headerTitle:function(page, type){
			var data = langCommon.pageHeader;
			return data[page][type][this.lang];
		},
		weekForm:function(yy, mm , ww){
			return langCommon.dateForm(yy,mm,ww);
		},
		getBible:function(bookCode) {
			bookCode = Number(bookCode);
			var book = langCommon.bibles;
			return book[this.lang||'eng'][bookCode];
		},
		bibleForm:function(bookCode, chapter, verseStart, verseEnd) {
			var bible = this.getBible(Number(bookCode));
			var strs=langCommon.bibleForm;
			return strs[this.lang||'eng'].split("BBBB").join(bible).split("CCCC").join(chapter).split("SSSS").join(verseStart).split("EEEE").join(verseEnd);
		},
		bibleStr:function(bookCode, chapter, verseStart, verseEnd) {
			var bible = this.getBible(Number(bookCode));
			var strs=langCommon.bibleStr;
			return strs[this.lang||'eng'].split("BBBB").join(bible).split("CCCC").join(chapter).split("SSSS").join(verseStart).split("EEEE").join(verseEnd);
		},
		fileTypeName:function(code) {
			var data = langCommon.fileTypeName;
			// console.log(data[this.lang||'kor']);
			return data[code][this.lang||'kor'];
		},
		openPreview(url, title) {
			title = title || '하이바이블 자료 보기';
			var  str='<div id="previewArea" class="preview_area"><iframe id="previewFrame" class="preview_frame" allowTransparency="true" width="100%" height="100%" src="'+url+'" title="content preview" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe><div class="preview_header">'+title+'</div><button type="button" id="closePreview">X CLOSE</button></div>';
			if (document.querySelectorAll(".preview_area").length) {
				this.closePreview(); 
			}
			document.querySelector('body').insertAdjacentHTML('beforeend', str);
			document.querySelector("#previewArea #closePreview").addEventListener("click", ()=>{
				this.closePreview();
			});
		},
		closePreview() {
			var el = document.querySelector(".preview_area");
			if (el) el.parentNode.removeChild(el); 
		},

	},
	mutations: {
		setLanguage(state, lang) {
			state.lang = lang;
		},
		setPageType(state, page) {
			// console.log(typeof page);
			if (typeof page === "string") {
				state.page = page;
				state.pageType = "_"+page;
			} else {
				state.page = page.name;
				state.pageType = "_"+page.name+' _type_'+page.type;
			}
		},
		setIsMobile(state) {
			var UserAgent = navigator.userAgent;
			if (UserAgent.match(/iPhone|iPod|iPad|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) != null || UserAgent.match(/LG|SAMSUNG|Samsung/) != null) state.isMobile = true;
			else state.isMobile = false;
		},
		readyCahngeLang(state, lang) {
			state.changeLang = lang;
		},
	},
	actions: {
	},
	modules: {
	}
})
