<template>
	<componunt :is="lang"></componunt>
</template>

<script>
import kor from './Kor'
import eng from './Eng'
import rus from './Rus'
import nep from './Nep'
import tai from './Tai'
import mog from './Mog'
import kaz from './Kaz'
import bur from './Bur'

export default{
	props: {
		lang:{type: String, default: 'kor'}
	},
	components:{
		kor, eng, rus, nep, tai, mog, kaz, bur
	},
	computed:{
	},
	data: () => ({
	})
}
</script>